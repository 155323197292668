<template>
  <div class="card">
    <h3>Contratos</h3>
    <TabMenu id="tabmenu" :model="items" :activeIndex="activeIndex" />
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          label: 'Parcelas',
          icon: 'pi pi-fw pi-home',
          to: { name: 'consignataria-contratos-parcelas' },
        },
      ],
      activeIndex: 0,
      tabIndex: {
        'consignataria-contratos-parcelas': 0,
      },
    }
  },

  mounted() {
    this.activeIndex = this.tabIndex[this.$route.name]
  },

  updated() {
    this.activeIndex = this.tabIndex[this.$route.name]
  },
}
</script>

<style lang="scss" scoped>
::v-deep
  .p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem
  .p-menuitem-link:not(.p-disabled):focus {
  box-shadow: none;
}

::v-deep .p-highlight .router-link-exact-active {
  color: var(--primary-color) !important;
}

::v-deep .p-menuitem-link {
  height: 103%;
}
</style>
